import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, TextField, Button } from '@mui/material';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';

dayjs.extend(isBetween);
dayjs.extend(utc);

const Usage = ({ userID }) => {
  const [fromDate, setFromDate] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [usageData, setUsageData] = useState([]);

  const fetchUsageData = async () => {
    const usageRef = collection(db, 'tokenUsage');
    const q = query(
      usageRef,
      where('userID', '==', userID),
      where('timestamp', '>=', dayjs(fromDate).toDate()),
      where('timestamp', '<=', dayjs(toDate).endOf('day').toDate())
    );

    const querySnapshot = await getDocs(q);
    const usageList = querySnapshot.docs.map(doc => doc.data());

    const groupedData = usageList.reduce((acc, usage) => {
      const key = `${usage.provider}-${usage.model}`;
      if (!acc[key]) {
        acc[key] = {
          provider: usage.provider,
          model: usage.model,
          inputTokens: 0,
          outputTokens: 0,
          totalUserCost: 0,
        };
      }
      acc[key].inputTokens += usage.inputTokens;
      acc[key].outputTokens += usage.outputTokens;
      acc[key].totalUserCost += usage.totalUserCost;
      return acc;
    }, {});

    setUsageData(Object.values(groupedData));
  };

  useEffect(() => {
    fetchUsageData();
  }, [fromDate, toDate]);

  const totalInputTokens = usageData.reduce((total, usage) => total + usage.inputTokens, 0);
  const totalOutputTokens = usageData.reduce((total, usage) => total + usage.outputTokens, 0);
  const totalCost = usageData.reduce((total, usage) => total + (usage.totalUserCost || 0), 0);

  return (
    <Box sx={{ padding: 3, maxWidth: '900px', margin: '0 auto', textAlign: 'left' }}>
      <Box sx={{ display: 'flex', gap: 2, mb: 3, flexWrap: 'wrap', alignItems: 'center', mt: 4 }}>
        <TextField
          label="From Date"
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            flexGrow: 1,
            maxWidth: '200px',
            backgroundColor: '#fff',
            borderRadius: '8px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
            },
          }}
        />
        <TextField
          label="To Date"
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            flexGrow: 1,
            maxWidth: '200px',
            backgroundColor: '#fff',
            borderRadius: '8px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
            },
          }}
        />
        <Button
          variant="contained"
          onClick={fetchUsageData}
          sx={{
            backgroundColor: '#000',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '8px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#333',
            },
          }}
        >
          Apply
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ borderRadius: '12px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
        <Table>
          <TableHead sx={{ backgroundColor: '#f4f4f4' }}>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>Provider</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>Model</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>Input Tokens</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>Output Tokens</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>Total Cost ($)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usageData.length > 0 ? (
              <>
                {usageData.map((usage, index) => (
                  <TableRow key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#fafafa' } }}>
                    <TableCell>{usage.provider}</TableCell>
                    <TableCell>{usage.model}</TableCell>
                    <TableCell>{usage.inputTokens}</TableCell>
                    <TableCell>{usage.outputTokens}</TableCell>
                    <TableCell>{usage.totalUserCost ? `$${usage.totalUserCost.toFixed(4)}` : 'N/A'}</TableCell>
                  </TableRow>
                ))}
                <TableRow sx={{ backgroundColor: '#e0e0e0' }}>
                  <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                  <TableCell></TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{totalInputTokens}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{totalOutputTokens}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{`$${totalCost.toFixed(4)}`}</TableCell>
                </TableRow>
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: 'center', color: '#888' }}>
                  No data available for the selected date range.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Usage;
