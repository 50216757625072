//import axios from 'axios';

export const getOpenAIResponse = async (model, prompt, imageUrls, userID, userName, chatHistory, onStreamUpdate) => {
  try {
    // Construct the request body
    const requestBody = JSON.stringify({
      model,
      prompt,
      imageUrls, // GCS URLs for OpenAI
      userID,
      userName,
      chatHistory // Pass the formatted chat history here
    });

    // Use the Fetch API to send the request with streaming support
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/openai`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: requestBody,
    });

    // Check if response is ok
    if (!response.ok) {
      throw new Error('Failed to fetch data from OpenAI');
    }

    // Get the stream reader
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let done = false;
    let accumulatedText = '';

    // Read the stream chunk by chunk
    while (!done) {
      const { value, done: readerDone } = await reader.read();
      done = readerDone;

      // Decode the chunk
      const chunk = decoder.decode(value, { stream: true });
      accumulatedText += chunk;

      // Call the onStreamUpdate callback with each chunk
      if (onStreamUpdate) {
        onStreamUpdate(chunk);
      }
    }

    return accumulatedText; // Return the full response after streaming ends
  } catch (error) {
    console.error('Error fetching data from OpenAI:', error);
    throw error;
  }
};
