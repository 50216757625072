//import axios from 'axios';

export const getGeminiResponse = async (model, prompt, userID, userName, chatHistory, fileUris, mimeTypes, onStreamUpdate) => {
    // Log the environment variable to check its value
    console.log('REACT_APP_API_URL:', process.env.REACT_APP_API_URL);


    try {
      console.log('fileUris:', fileUris); // Debugging log
      console.log('mimeTypes:', mimeTypes); // Debugging log
  
      const contentParts = fileUris.map((uri, index) => ({
        fileData: {
          mimeType: mimeTypes[index],
          fileUri: uri,
        },
      }));
  
      contentParts.push({ text: prompt });
  
      

      // Corrected API call in geminiService.js
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/gemini`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model,
          userID,
          userName,
          chatHistory,
          contentParts,
        }),
      });

  
      // Check if response is valid
      if (!response.ok) throw new Error('Failed to fetch response from Gemini');
  
      const reader = response.body.getReader(); // Get the stream reader
      const decoder = new TextDecoder(); // Decode stream as text
  
      let done = false;
      let accumulatedText = '';
  
      // Read the stream chunk by chunk
      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;
  
        // Decode the received chunk
        const chunk = decoder.decode(value, { stream: true });
        accumulatedText += chunk;

        // Log the chunks received to debug the flow
        console.log('Received chunk:', chunk);
  
        // Call the onStreamUpdate callback with each chunk
        if (onStreamUpdate) {
          onStreamUpdate(chunk);
        }
      }
  
      return accumulatedText; // Return the complete response after streaming ends
    } catch (error) {
      console.error('Error fetching data from Gemini:', error);
      throw error;
    }
  };
  