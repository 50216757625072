import React, { useState, useEffect } from 'react';
import { auth, provider } from './firebase';
import { signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Chat from './components/Chat';
import Usage from './components/Usage'; // Import the Usage component
import Button from '@mui/material/Button';
import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState('Google Gemini');
  const [selectedModel, setSelectedModel] = useState('gemini-1.5-flash');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log("Current User: ", currentUser);
      setUser(currentUser);

      if (currentUser) {
        // Only navigate to /chat if the user is not already on a specific conversation or another path
        if (location.pathname === '/') {
          console.log("Navigating to /chat");
          navigate('/chat');
        }
      } else {
        console.log("Navigating to /");
        setSidebarOpen(false);
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate, location.pathname]);

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        setUser(result.user);
      })
      .catch((error) => {
        console.error('Error during sign-in:', error);
      });
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="App">
      <Sidebar open={sidebarOpen} toggleSidebar={toggleSidebar} userID={user?.uid} />
      <div style={{ marginLeft: sidebarOpen ? '250px' : '0px', transition: 'margin-left 0.3s' }}>
        <Header
          user={user}
          toggleSidebar={toggleSidebar}
          sidebarOpen={sidebarOpen}
          onProviderChange={setSelectedProvider}
          onModelChange={setSelectedModel}
        />
        <Routes>
          <Route
            path="/chat/:conversationID"
            element={
              user ? (
                <Chat
                  selectedProvider={selectedProvider}
                  selectedModel={selectedModel}
                  userID={user.uid}
                  userName={user.displayName}
                />
              ) : (
                <header className="App-header" style={{ backgroundColor: '#282c34', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'white' }}>
                  <h1>Welcome to Aggrefy.ai</h1>
                  <p>Please sign in with Google to continue.</p>
                  <Button variant="contained" color="primary" onClick={signInWithGoogle}>
                    Sign in with Google
                  </Button>
                </header>
              )
            }
          />
          <Route
            path="/chat"
            element={
              user ? (
                <Chat
                  selectedProvider={selectedProvider}
                  selectedModel={selectedModel}
                  userID={user.uid}
                  userName={user.displayName}
                />
              ) : (
                <header className="App-header" style={{ backgroundColor: '#282c34', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'white' }}>
                  <h1>Welcome to Aggrefy.ai</h1>
                  <p>Please sign in with Google to continue.</p>
                  <Button variant="contained" color="primary" onClick={signInWithGoogle}>
                    Sign in with Google
                  </Button>
                </header>
              )
            }
          />
          <Route
            path="/usage"
            element={
              user ? (
                <Usage userID={user.uid} /> // Render the Usage component for the /usage route
              ) : (
                <header className="App-header" style={{ backgroundColor: '#282c34', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'white' }}>
                  <h1>Welcome to Aggrefy.ai</h1>
                  <p>Please sign in with Google to continue.</p>
                  <Button variant="contained" color="primary" onClick={signInWithGoogle}>
                    Sign in with Google
                  </Button>
                </header>
              )
            }
          />
          {/* Default route for non-logged-in users */}
          <Route
            path="/"
            element={
              <header className="App-header" style={{ backgroundColor: '#282c34', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'white' }}>
                <h1>Welcome to Aggrefy.ai</h1>
                <p>Please sign in with Google to continue.</p>
                <Button variant="contained" color="primary" onClick={signInWithGoogle}>
                  Sign in with Google
                </Button>
              </header>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
