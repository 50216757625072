import React, { useEffect, useState } from 'react';
import { Box, IconButton, List, ListItem, ListItemText, Typography, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, query, where, orderBy, limit, onSnapshot, startAfter } from 'firebase/firestore';
import dayjs from 'dayjs';

const Sidebar = ({ open, toggleSidebar, userID }) => {
  const [conversations, setConversations] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (userID) {
      const conversationsRef = collection(db, 'conversations');
      const q = query(
        conversationsRef,
        where('userID', '==', userID),
        orderBy('timestamp', 'desc'),
        limit(15)  // Load the initial 15 conversations
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const conversationList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]); // Store the last document for pagination
        setConversations(conversationList);
      });

      return () => unsubscribe();
    }
  }, [userID]);

  const loadMoreConversations = () => {
    if (loadingMore || !lastDoc) return;
    setLoadingMore(true);

    const conversationsRef = collection(db, 'conversations');
    const q = query(
      conversationsRef,
      where('userID', '==', userID),
      orderBy('timestamp', 'desc'),
      startAfter(lastDoc),  // Start after the last document from the previous query
      limit(10)  // Load 10 more conversations
    );

    onSnapshot(q, (snapshot) => {
      const newConversations = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLastDoc(snapshot.docs[snapshot.docs.length - 1]); // Update the last document
      setConversations((prev) => [...prev, ...newConversations]);
      setLoadingMore(false);
    });
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 10) {
      loadMoreConversations();
    }
  };

  const handleConversationClick = (conversationID) => {
    navigate(`/chat/${conversationID}`);
  };

  const handleNewChat = () => {
    navigate('/chat');
  };

  const groupConversations = () => {
    const today = [];
    const yesterday = [];
    const last7Days = [];
    const earlier = [];

    conversations.forEach((conversation) => {
      const lastMessageTimestamp = dayjs(conversation.timestamp?.toDate());

      if (lastMessageTimestamp.isSame(dayjs(), 'day')) {
        today.push(conversation);
      } else if (lastMessageTimestamp.isSame(dayjs().subtract(1, 'day'), 'day')) {
        yesterday.push(conversation);
      } else if (lastMessageTimestamp.isAfter(dayjs().subtract(7, 'day'))) {
        last7Days.push(conversation);
      } else {
        earlier.push(conversation);
      }
    });

    return { today, yesterday, last7Days, earlier };
  };

  const { today, yesterday, last7Days, earlier } = groupConversations();

  const renderConversations = (group, label) => (
    <>
      {group.length > 0 && (
        <>
          <Typography 
            sx={{ 
              fontSize: '0.75rem', 
              color: 'black', 
              marginTop: 2, 
              marginBottom: 0.1,  // Reduced margin between label and first conversation
              fontWeight: '600', 
              textAlign: 'left',
              paddingLeft: 3,
            }}
          >
            {label}
          </Typography>
          <List sx={{ paddingX: 1 }}>
            {group.map((conversation) => (
              <ListItem
                button
                key={conversation.id}
                onClick={() => handleConversationClick(conversation.id)}
                sx={{
                  '&:hover': {
                    backgroundColor: '#ececec',
                  },
                  backgroundColor: window.location.pathname.includes(conversation.id) ? '#ececec' : 'inherit',
                  borderRadius: '10px',
                  marginY: '1px',  // Reduced margin between conversations
                  paddingY: '5px',  // Reduced height of individual conversations
                }}
              >
                <ListItemText
                  primaryTypographyProps={{ noWrap: true, fontSize: '0.875rem' }}
                  secondaryTypographyProps={{ fontSize: '0.75rem' }}
                  primary={conversation.messages[conversation.messages.length - 1]?.content || 'New Conversation'}
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </>
  );

  return (
    <Box
      sx={{
        width: open ? '250px' : '0px',
        transition: 'width 0.3s',
        overflow: 'hidden',
        backgroundColor: '#f9f9f9',
        height: '100vh',
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: 1000,
        paddingTop: '10px',
        overflowY: 'auto',
        scrollbarWidth: 'thin', // For Firefox
        '&::-webkit-scrollbar': {
          width: '4px',  // For Chrome, Edge
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#B0B0B0',  // Scrollbar color
          borderRadius: '10px',
        },
      }}
      onScroll={handleScroll}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pl: 2, pr: 2 }}>
        <IconButton onClick={toggleSidebar}>
          <MenuIcon />
        </IconButton>
      </Box>
      {open && (
        <>
          <Box sx={{ paddingLeft: 2, paddingTop: 1 }}>
            <Button
              onClick={handleNewChat}
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#E3E3E3',
                borderRadius: '20px',
                paddingY: '5px',
                paddingLeft: '10px',
                paddingRight: '20px',
                width: 'max-content',
                color: 'black',  // Set icon and text color to black
                textTransform: 'none', // Ensure text is not transformed to uppercase
                '&:hover': {
                  backgroundColor: '#D3D3D3',
                }
              }}
            >
              <AddIcon sx={{ marginRight: 1 }} />
              <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>New chat</Typography> {/* Sentence case */}
            </Button>
          </Box>
          {renderConversations(today, 'Today')}
          {renderConversations(yesterday, 'Yesterday')} {/* Added Yesterday */}
          {renderConversations(last7Days, 'Last 7 Days')}
          {renderConversations(earlier, 'Earlier')}
        </>
      )}
    </Box>
  );
};

export default Sidebar;
